import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import ModularPage from "../templates/ModularPage";
import { termsPage } from "../types";

export const TermsConditionsPageEn = ({
  data: {
    data: { conditionEn },
  },
}) => {
  const { title, description, content, seoMetaTags } = conditionEn;

  return (
    <ModularPage
      title={title}
      description={description}
      content={content}
      seoMetaTags={seoMetaTags}
    />
  );
};

TermsConditionsPageEn.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      conditionEn: PropTypes.shape(termsPage).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query getTermsConditionsEn {
    data: dato {
      conditionEn {
        seoMetaTags: _seoMetaTags {
          tag
          content
          attributes
        }
        title
        description
        content {
          ... on DatoCMS_BasicTitleDescriptionRecord {
            __typename
            id
            title
            description
            largeDescription
          }
          ... on DatoCMS_ImageRecord {
            __typename
            id
            source {
              url
            }
          }
          ... on DatoCMS_TextColumnRecord {
            __typename
            id
            title
            descriptionRight
            descriptionLeft
          }
        }
      }
    }
  }
`;

export default TermsConditionsPageEn;
